import React from "react";
import { Container, Form, Button } from "react-bootstrap";
import "./Signup.css";
import { Link, useNavigate } from "react-router-dom";

function Signup() {
    const navigate = useNavigate();
    const handleSumbit = (event) => {
        event.preventDefault(); // Prevents default form submission behavior
        const name = event.target.elements[0].value;
        const email = event.target.elements[1].value;
        const password = event.target.elements[2].value;
        const confirmPassword = event.target.elements[3].value;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            name: name,
            email: email,
            password: password,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch("http://localhost:8000/ask", requestOptions)
            .then((_response) => navigate("/login"))
            .catch((error) => console.error(error));
    };

    return (
        <div className="signup-background">
            <Container>
                <div className="signup-form-container">
                    <h2 className="signup-heading">Sign Up</h2>
                    <Form className="signup-form" onSubmit={handleSumbit}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name" />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" />
                        </Form.Group>

                        <Form.Group controlId="formBasicConfirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" placeholder="Confirm Password" />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Sign Up
                        </Button>
                    </Form>
                    <div className="signup-link">
                        <Link to={"/login"}>Login to a existing account</Link>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Signup;
