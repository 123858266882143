import { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import "./Nutrition.css";

function Nutrition() {
    const [response, setResponse] = useState("");

    const handleSumbit = (event) => {
        event.preventDefault(); // Prevents default form submission behavior
        const input = event.target.elements[0].value;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            user_name: "jo",
            prompt: input,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch("http://localhost:8000/ask", requestOptions)
            .then((response) => response.json())
            .then((data) => setResponse(data.response))
            .catch((error) => console.error(error));
    };

    const displayReponse = () => {
        if (response?.length > 1) {
            return <p>{response}</p>;
        }

        return <p>No response yet.</p>;
    };

    return (
        <Container>
            <h1>Nutrition AI</h1>
            <Form onSubmit={handleSumbit}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Ask your question here</Form.Label>
                    <Form.Control type="text" placeholder="Enter your question" />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
            <div className="response-container">
                <h3>Response:</h3>
                {displayReponse()}
            </div>
        </Container>
    );
}

export default Nutrition;
