import { useState } from "react";
import { Container, Form, Button, Card } from "react-bootstrap";
import logo from "../assets/FW-Transparent.png";
import Footer from "./Footer";
import "./Gym.css";

function Gym() {
    const [response, setResponse] = useState("");

    const handleSumbit = (event) => {
        event.preventDefault(); // Prevents default form submission behavior
        const input = event.target.elements[0].value;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            user_name: "jo",
            prompt: input,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch("http://localhost:8000/ask", requestOptions)
            .then((response) => response.json())
            .then((data) => setResponse(data.response))
            .catch((error) => console.error(error));
    };

    const displayReponse = () => {
        if (response?.length > 1) {
            return <p>{response}</p>;
        }
        return null;
    };

    return (
        <div>
            <Card className="logo-card">
                <img src={logo} alt="Logo" />
                <Card.Title className="logo">FutureWave</Card.Title>
            </Card>
            <Container>
                <h1 className="heading">Maxi</h1>
                <h6>Explain your fitness goals to Maxi and he will help you achieve them.</h6>
                <div className="dialog-container">
                    <div>user: What workout should i do to make my biceps grow?</div>
                    <div>Maxi: this is the workout you should be doing...</div>
                    <div></div>
                    {displayReponse()}
                </div>
                <Form onSubmit={handleSumbit}>
                    <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="Enter your question" />
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form.Group>
                </Form>
            </Container>
            <Footer />
        </div>
    );
}

export default Gym;
