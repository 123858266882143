import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
    return (
        <div className="border-top">
            <div className="footer-container">
                <div className="button-group">
                    <div>Futurewave</div>
                    <div>2021 @ Lando</div>
                    <div>All rights reserved.</div>
                </div>
                <div className="button-group">
                    <Link>Home</Link>
                    <Link>Pricing</Link>
                    <Link>Contact</Link>
                    <Link>About us</Link>
                </div>
                <div className="button-group">
                    <Link>Facebook</Link>
                    <Link>Instagram</Link>
                    <Link>Twitter</Link>
                    <Link>Linkedin</Link>
                </div>
                <div className="button-group">
                    <Link>Privacy policy</Link>
                    <Link>Terms of service</Link>
                </div>
            </div>
        </div>
    );
};

export default Footer;
