// import { FaDumbbell, FaHeart } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";
// import Header from "./Header";
// import styles from "./home.module.css";

// const Home = () => {
//     const navigate = useNavigate();
//     return (
//         <div>
//             <Header />
//             <div className={styles.mainDiv}>
//                 <div>
//                     <button className={styles.button} onClick={() => navigate("gym")}>
//                         <FaDumbbell />
//                         <div>Gym AI</div>
//                     </button>
//                 </div>
//                 <div>
//                     <button className={styles.button} onClick={() => navigate("nutrition")}>
//                         <FaHeart />
//                         <div>Nutrition AI</div>
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Home;

import React from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell, faCarrot } from "@fortawesome/free-solid-svg-icons";

function Home() {
    return (
        <div className="home-container">
            <div className="home-header">
                <h1 className="home-title">Welcome to Future Wave AI</h1>
                <Link to="/login">
                    <Button variant="outline-danger" className="home-sign-out-btn">
                        Sign Out
                    </Button>
                </Link>
            </div>
            <Container className="home-content">
                <div className="home-button-container">
                    <Link to="/gym" className="home-button home-gym-ai-btn">
                        <h3>Gym AI</h3> <FontAwesomeIcon icon={faDumbbell} className="mr-2" />
                    </Link>
                    <Link to="/nutrition" className="home-button home-nutrition-ai-btn">
                        <h3>Nutrition AI</h3> <FontAwesomeIcon icon={faCarrot} className="mr-2" />
                    </Link>
                </div>
            </Container>
        </div>
    );
}

export default Home;
