import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NewLandingPage.css";
import logo from "../assets/FW-Transparent.png";

const NewLandingPage = () => {
    return (
        <div>
            <Card className="logo-card">
                <img src={logo} alt="Logo" />
                <Card.Title className="logo">FutureWave</Card.Title>
            </Card>
            <Card className="landing-card">
                <Card.Title>Discover the Future with AI</Card.Title>
                <Card.Body>
                    <Card.Text>
                        Welcome to the Futurewave, where you can find the most innovative and high-quality AI products.
                        Explore our catalog and find the perfect solution for your business.
                    </Card.Text>
                    {/* <Link to={"/signup"}>
                        <Button variant="primary" className="mr-2">
                            Get Started
                        </Button>
                    </Link> */}
                </Card.Body>
            </Card>
            <Card className="landing-card">
                <Card.Title>The AI Marketplace</Card.Title>
                <Card.Body>
                    <Card.Text>
                        The Futurewave AI Marketplace is a one-stop-shop for all your AI needs. We provide a wide range
                        of AI products that a created to make your everyday life a little easier. Whether you're a small
                        business or a large corporation, we have the right tool for you.
                    </Card.Text>
                    <div>
                        <div className="flex-row">
                            <Card className="landing-card mini-card">
                                <div className="card-sub-title">Data Analysis</div>
                                <Card.Body>
                                    <Card.Text>
                                        Our products can gather, transform, visualize, and make sense of your business
                                        data without requiring technical skills.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className="landing-card mini-card">
                                <div className="card-sub-title">Artificial Intelligence</div>
                                <Card.Body>
                                    <Card.Text>
                                        We provide cutting-edge AI algorithms and platforms that allow industrial and
                                        commercial enterprises to develop and deploy intelligent systems that automate
                                        complex decisions and processes, analyze data, and adapt to changes.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="flex-row">
                            <Card className="landing-card mini-card">
                                <div className="card-sub-title">Innovation</div>
                                <Card.Body>
                                    <Card.Text>
                                        We collaborate with startups and technology firms to bring you the most
                                        innovative and scalable AI solutions.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className="landing-card mini-card">
                                <div className="card-sub-title">Security</div>
                                <Card.Body>
                                    <Card.Text>
                                        We take data security seriously and offer security features to protect your
                                        confidential data.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card className="landing-card">
                <Card.Title>What We Do</Card.Title>
                <Card.Body>
                    <Card.Text>
                        We help you harness the power of AI. Our goal is to provide you with your own AI Expert so you
                        can live a more healthy, efficient, and joyful life. Our experts are available to guide you
                        through the selection process and answer any questions you have.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card className="landing-card">
                <Card.Title>Our AI Products</Card.Title>
                <Card.Body>
                    <Card.Text>
                        We are proud to offer a wide range of AI products and solutions from industry-leading
                        developers. Our products are designed to meet the needs of various industries, including
                        banking, healthcare, retail, and manufacturing.
                        <div className="flex-row">
                            <div className="section">
                                <div>
                                    <img
                                        className="small-image"
                                        src="https://i.pinimg.com/originals/d7/46/e2/d746e2e8654b3aba08b1cbfa285a49f3.jpg"
                                    />
                                </div>
                                <div className="card-sub-title">KiraAI - Personal Trainer</div>
                                <div>
                                    Bring the future to life a smart personal trainer to reach your fitness goals.
                                </div>
                            </div>
                            <div className="section">
                                <div>
                                    <img
                                        className="small-image"
                                        src="	https://radiusstaffingsolutions.com/wp-content/uploads/2021/11/dietitian-nutritionist-radius.jpg"
                                    />
                                </div>
                                <div className="card-sub-title">SamAI - Nutrition Expert</div>

                                <div>
                                    Use AI to create meal plans, ask nutrition questions, and guide you to a healthier
                                    you.
                                </div>
                            </div>
                            <div className="section">
                                <div>
                                    <img
                                        className="small-image"
                                        src="https://cdn.gamma.app/cdn-cgi/image/quality=80,fit=scale-down,onerror=redirect,width=500/hgwwi8v8er9j5oq/d238bd448cf34d569bf38a9a2d7a0cac/original/mobile-voice-assistant.jpg"
                                    />
                                </div>
                                <div className="card-sub-title">MaxiAI - Voice assistants</div>

                                <div>
                                    Streamline your communication with customers and employees with natural language
                                    processing.
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card className="landing-card">
                <Card.Title>How it Works</Card.Title>
                <Card.Body>
                    <Card.Text>
                        The AI Marketplace is a straightforward and easy-to-use platform that enables you to discover,
                        test, and utilize the best AI solutions for you.
                    </Card.Text>
                </Card.Body>
            </Card>
            {/* <Card className="landing-card">
                <Card.Title>Our Customers</Card.Title>
                <Card.Body>
                    <Card.Text>
                        We are proud to serve a variety of industries and customers who trust us to provide them with
                        the best AI solutions.
                        <div className="flex-row">
                            <div>
                                <div className="card-sub-title">Banking</div>
                                <ul>
                                    <li>Bank of America</li>
                                    <li>JPMorgan Chase</li>
                                    <li>Citigroup</li>
                                </ul>
                            </div>
                            <div>
                                <div className="card-sub-title">Healthcare</div>

                                <ul>
                                    <li>Mayo Clinic</li>
                                    <li>Johns Hopkins Hospital</li>
                                    <li>Kaiser Permanente</li>
                                </ul>
                            </div>
                            <div>
                                <div className="card-sub-title">Retail</div>

                                <ul>
                                    <li>Amazon</li>
                                    <li>Walmart</li>
                                    <li>Target</li>
                                </ul>
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card> */}
            <Card className="landing-card">
                <Card.Title>Get Started</Card.Title>
                <Card.Body>
                    <Card.Text>
                        Ready to explore the AI Marketplace? Here's how to get started.
                        <ul>
                            <li>
                                Sign up for our newsletter to receive updates on the latest AI products and insights.
                            </li>
                            <li>Browse our products and services to find the right solution for your business.</li>
                            <li>Contact us to schedule a demo or learn more about our products.</li>
                        </ul>
                    </Card.Text>
                    {/* <Link to={"/signup"}>
                        <Button variant="primary" className="mr-2">
                            Get Started
                        </Button>
                    </Link> */}
                </Card.Body>
            </Card>
            <Card className="landing-card">
                <Card.Title>Contact Us</Card.Title>
                <Card.Body>
                    <Card.Text>
                        Have questions or want to learn more about the AI Marketplace? Contact us. <br />
                        Email: <b>contact@futurewave.ai</b>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
};

export default NewLandingPage;
