import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Gym from "./components/Gym";
import Nutrition from "./components/Nutrition";
import Login from "./components/Login";
import Signup from "./components/Signup";
import LandingPage from "./components/LandingPage";
import NewLandingPage from "./components/NewLandingPage";

import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const App = () => {
    return (
        <Router>
            <div>
                <Routes>
                    <Route exact path="/" element={<NewLandingPage />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/gym" element={<Gym />} />
                    <Route path="/nutrition" element={<Nutrition />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
