import React from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import "./Login.css";

function Login() {
    const navigate = useNavigate();
    const handleSumbit = (event) => {
        event.preventDefault(); // Prevents default form submission behavior
        const email = event.target.elements[0].value;
        const password = event.target.elements[1].value;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            email: email,
            password: password,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch("http://localhost:8000/ask", requestOptions)
            .then((_response) => navigate("/home"))
            .catch((error) => console.error(error));
    };

    return (
        <div className="login-bg">
            <div className="login-container">
                <h2 className="login-heading">Login</h2>
                <Form className="login-form" onSubmit={handleSumbit}>
                    <Form.Group className="form-floating">
                        <Form.Control type="email" placeholder="name@example.com" />
                        <Form.Label>Email address</Form.Label>
                    </Form.Group>

                    <Form.Group className="form-floating">
                        <Form.Control type="password" placeholder="Password" />
                        <Form.Label>Password</Form.Label>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Login
                    </Button>
                </Form>
                <div>
                    <Link to={"/signup"}>Create a new account</Link>
                </div>
            </div>
        </div>
    );
}

export default Login;
