import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./LandingPage.css";
import logoVideo from "../assets/Future-wave-ai.mp4";

function LandingPage() {
    // image is from here: https://www.healthkart.com/connect/bodybuilding-diet-plan-for-beginners/bid-5560
    return (
        <Container>
            <Card className="landing-card">
                <video autoPlay loop muted>
                    <source src={logoVideo} type="video/mp4" />
                </video>
                <Card.Body>
                    <Card.Text>
                        Welcome to our AI marketplace, your one-stop-shop for all your AI needs. Our platform offers a
                        wide range of AI-powered solutions for individuals and businesses alike, whether you're looking
                        to improve your productivity, streamline your operations, or enhance your customer experience.
                        With our carefully curated selection of AI products and services, you can find everything you
                        need to stay ahead of the curve in today's rapidly changing technological landscape.
                        <br />
                        <br />
                        Our team of expert AI developers and data scientists work tirelessly to ensure that each product
                        on our platform is top-of-the-line, reliable, and easy to use. Plus, with our user-friendly
                        interface, you can easily browse, purchase, and implement the solutions that are right for you.
                        <br />
                        <br />
                        Join our AI marketplace today and unlock the full potential of artificial intelligence for your
                        business or personal needs.
                    </Card.Text>
                    <Link to={"/signup"}>
                        <Button variant="primary" className="mr-2">
                            Sign Up
                        </Button>
                    </Link>
                    <Link to={"/login"}>
                        <Button variant="outline-primary" className="text-white mx-2">
                            Login
                        </Button>
                    </Link>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default LandingPage;
